// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import Example from "https://framer.com/m/framer/Example.js@^1.0.0"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function BuyButtonStore(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: `<div class="bc-buy-button" id="bc-sku-120WH-SAGUTO" style="width:295px;height:435px">
    <iframe
     src="https://ad.buybutton.store/card/v1.6/mye1w6g1es/1?action=checkout&open_in=popup&src=buy_button&ga_id=null&sku=120WH-SAGUTO"
     border="0"
     scrolling="no"
     horizontalscrolling="no"
     verticalscrolling="no"
     allowtransparency="true"
     width="100%"
     height="100%"
     style="overflow: hidden; border: none; width=100%; height=100%;">
    </iframe>
   </div>
 
<div class="bc-buy-button" id="bc-sku-6AM-SAGUTO" style="width:295px;height:435px">
    <iframe
     src="https://ad.buybutton.store/card/v1.6/mye1w6g1es/1?action=checkout&open_in=popup&src=buy_button&ga_id=null&sku=6AM-SAGUTO"
     border="0"
     scrolling="no"
     horizontalscrolling="no"
     verticalscrolling="no"
     allowtransparency="true"
     width="100%"
     height="100%"
     style="overflow: hidden; border: none; width=100%; height=100%;">
    </iframe>
   </div>
 
<div class="bc-buy-button" id="bc-sku-LM3W-SAGUTO" style="width:295px;height:435px">
    <iframe
     src="https://ad.buybutton.store/card/v1.6/mye1w6g1es/1?action=checkout&open_in=popup&src=buy_button&ga_id=null&sku=LM3W-SAGUTO"
     border="0"
     scrolling="no"
     horizontalscrolling="no"
     verticalscrolling="no"
     allowtransparency="true"
     width="100%"
     height="100%"
     style="overflow: hidden; border: none; width=100%; height=100%;">
    </iframe>
   </div>
 
<div class="bc-buy-button" id="bc-sku-DG3W-SAGUTO" style="width:295px;height:435px">
    <iframe
     src="https://ad.buybutton.store/card/v1.6/mye1w6g1es/1?action=checkout&open_in=popup&src=buy_button&ga_id=null&sku=DG3W-SAGUTO"
     border="0"
     scrolling="no"
     horizontalscrolling="no"
     verticalscrolling="no"
     allowtransparency="true"
     width="100%"
     height="100%"
     style="overflow: hidden; border: none; width=100%; height=100%;">
    </iframe>
   </div>
 
<div class="bc-buy-button" id="bc-sku-TOWEL-SAGUTO" style="width:295px;height:435px">
    <iframe
     src="https://ad.buybutton.store/card/v1.6/mye1w6g1es/1?action=checkout&open_in=popup&src=buy_button&ga_id=null&sku=TOWEL-SAGUTO"
     border="0"
     scrolling="no"
     horizontalscrolling="no"
     verticalscrolling="no"
     allowtransparency="true"
     width="100%"
     height="100%"
     style="overflow: hidden; border: none; width=100%; height=100%;">
    </iframe>
   </div>`,
            }}
        />
    )
}
